<template>
  <div class="outer-page">
    <div class="center-width">
      <div class="content">
        <div class="welcome">
          <h6>您好 HELLO！</h6>
          <p>
            感谢您来到{{
              data.companyName
            }}，若您有意见或者建议，请为我们留言或者使用以下方式联系我们，我们将尽快给您回复，并为您提供最真诚的服务，谢谢。
          </p>
        </div>
        <div class="contact">
          <div class="contact-code">
            <img :src="data.servicePath + data.companyRqcode" />
          </div>
          <div class="contact-list">
            <ul>
<!--              <li>-->
<!--                电话：{{ data.companyTel }}-->
<!--                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-->
<!--              </li>-->
              <li>
                全国销售热线：400-133-8979
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </li>
              <li>
                售后客服专线：023-68866321
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </li>
              <li>邮箱：{{ data.companyEmail }} &nbsp;&nbsp;</li>
              <li>
                地址：{{ data.companyAddress }}
                &nbsp;
              </li>
            </ul>
          </div>
          <div class="clears"></div>
        </div>
        <div class="msgform">
          <div class="import">
            <p>姓名/Name</p>
            <input type="text" v-model="contactForm.messagePeople" />
          </div>
          <div class="import">
            <p>电话/Phone</p>
            <input type="text" v-model="contactForm.tel" />
          </div>
          <div class="import">
            <p>信息/Information</p>
            <textarea
              style="height: 7rem"
              :rows="5"
              v-model="contactForm.messageContent"
            />
          </div>
          <div class="import canvas">
            <input
              type="text"
              style="width: 7rem"
              v-model="code"
              placeholder="请输入验证码"
            />
            <div class="code-info">
              <canvas
                ref="cv"
                id="canvas"
                style="width: 4rem; height: 1.5rem"
              ></canvas>
              <a @click="cutCode">看不清，换一张</a>
            </div>
          </div>
          <div class="submit-btn">
            <button type="button" @click="submit">send</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      data: [],
      contactForm: {
        messagePeople: "",
        tel: "",
        messageContent: "",
      },
      code: "",
      show_num: [],
    };
  },
  methods: {
    getList() {
      this.$post("getcompany").then((res) => {
        this.data = res;
      });
    },
    submit() {
      if (this.contactForm.messagePeople == "") {
        this.$Message.error("姓名不能为空！");
      } else if (this.contactForm.tel == "") {
        this.$Message.error("电话不能为空！");
      } else if (this.contactForm.messageContent == "") {
        this.$Message.error("内容不能为空！");
      } else {
        let num = this.show_num.join("");
        let val = this.code.toLowerCase();
        if (val == "") {
          this.$Message.error("请输入验证码");
          return;
        } else if (val != num) {
          this.$Message.error("验证码错误，请重新输入");
          this.show_num = [];
          this.code = [];
          this.draw();
          return;
        }
        this.$post(this.$api.SEND_MESSAGE, this.contactForm)
          .then(() => {
            this.$Message.success({
              content: "您的留言发送成功，我们会尽快与您联系",
              duration: 3,
            });
          })
          .finally(() => {
            this.contactForm = {
              messagePeople: "",
              tel: "",
              messageContent: "",
            };
            this.show_num = [];
            this.code = "";
            this.draw();
          });
      }
    },
    draw() {
      let canvas_width = this.$refs.cv.clientWidth;
      let canvas_height = 30;
      let canvas = document.getElementById("canvas"); //获取到canvas的对象
      let context = canvas.getContext("2d"); //获取到canvas画图的环境
      canvas.width = canvas_width;
      canvas.height = canvas_height;
      let sCode =
        "a,b,c,d,e,f,g,h,i,j,k,m,n,p,q,r,s,t,u,v,w,x,y,z,A,B,C,E,F,G,H,J,K,L,M,N,P,Q,R,S,T,W,X,Y,Z,1,2,3,4,5,6,7,8,9,0";
      let aCode = sCode.split(",");
      let aLength = aCode.length; //获取到数组的长度
      for (let i = 0; i < 4; i++) {
        //for循环可以控制验证码位数（如果想显示6位数，4改成6即可）
        let j = Math.floor(Math.random() * aLength); //获取到随机的索引值
        let deg = Math.random() - 0.5; //产生一个随机弧度
        let txt = aCode[j]; //得到随机的一个内容
        this.show_num[i] = txt.toLowerCase();
        let x = 10 + i * 20; //文字在canvas上的x坐标
        let y = 20 + Math.random() * 8; //文字在canvas上的y坐标
        context.font = "bold 23px 微软雅黑";
        context.translate(x, y);
        context.rotate(deg);
        context.fillStyle = this.randomColor();
        context.fillText(txt, 0, 0);
        context.rotate(-deg);
        context.translate(-x, -y);
      }
      for (let i = 0; i <= 5; i++) {
        //验证码上显示线条
        context.strokeStyle = this.randomColor();
        context.beginPath();
        context.moveTo(
          Math.random() * canvas_width,
          Math.random() * canvas_height
        );
        context.lineTo(
          Math.random() * canvas_width,
          Math.random() * canvas_height
        );
        context.stroke();
      }
      for (let i = 0; i <= 30; i++) {
        //验证码上显示小点
        context.strokeStyle = this.randomColor();
        context.beginPath();
        let x = Math.random() * canvas_width;
        let y = Math.random() * canvas_height;
        context.moveTo(x, y);
        context.lineTo(x + 1, y + 1);
        context.stroke();
      }
    },
    //得到随机的颜色值
    randomColor() {
      let r = Math.floor(Math.random() * 256);
      let g = Math.floor(Math.random() * 256);
      let b = Math.floor(Math.random() * 256);
      return "rgb(" + r + "," + g + "," + b + ")";
    },
    cutCode() {
      this.show_num = [];
      this.draw();
    },
  },
  mounted() {
    this.getList();
    this.draw();
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  padding: 0.5rem 0;
  font-size: 0.577rem;
  line-height: 1rem;
  .center-width {
    width: 94%;
    margin: 0 3%;
    .content {
      font-size: 0.577rem;
      line-height: 0.88rem;
      color: #666666;
      //   padding-top: 0.5rem;
      .welcome {
        font-size: 0.533rem;
        color: #666666;
        h6 {
          font-size: 0.667rem;
          color: #242424;
          margin-bottom: 0.3rem;
        }
      }
      .contact {
        margin-top: 1rem;
        .contact-code {
          float: left;
          width: 3.2rem;
          height: 3.2rem;
          img {
            display: block;
            width: 3.2rem;
            height: 3.2rem;
          }
        }
        .contact-list {
          float: right;
          display: block;
          width: 75%;
          ul {
            list-style: none;
            li {
              padding-left: 1.2rem;
              line-height: 1rem;
              font-size: 0.533rem;
              color: #666666;

              &:nth-child(1) {
                background: url(../../../../public/imgs/icon/ct01.png) 2px
                  0.2rem no-repeat;
                background-size: 0.588rem 0.7rem !important;
              }
              &:nth-child(2) {
                background: url(../../../../public/imgs/icon/ct01.png) 2px
                0.2rem no-repeat;
                background-size: 0.588rem 0.7rem !important;
              }
              &:nth-child(3) {
                background: url(../../../../public/imgs/icon/ct02.png) left
                  0.2rem no-repeat;
                background-size: 0.7rem 0.55rem !important;
              }
              &:nth-child(4) {
                background: url(../../../../public/imgs/icon/ct03.png) 1px
                  0.2rem no-repeat;
                background-size: 0.588rem 0.7rem !important;
              }
            }
          }
        }
        .clears {
          clear: both;
          line-height: 0;
          overflow: hidden;
          font-size: 0;
          height: 0;
        }
      }
      .msgform {
        margin-top: 1rem;
        .import {
          margin-bottom: 0.3rem;
          p {
            padding-left: 0.3rem;
            font-size: 0.533rem;
            line-height: 1.333rem;
          }
          textarea,
          input {
            width: 100%;
            height: 1.644rem;
            line-height: 1.644rem;
            background: #e3e3e3;
            border: none;
            padding: 0 0.3rem;
            box-sizing: border-box;
            border-radius: 10px;
            resize: none;
            &:focus {
              outline: none;
            }
          }
        }
        .canvas {
          display: flex;
          justify-content: space-between;
        }
        .code-info {
          display: flex;
          align-items: center;
          a {
            font-size: 12px;
          }
        }
        .submit-btn {
          text-align: center;
          margin-top: 0.5rem;
          button {
            display: inline-block;
            width: 4.86rem;
            height: 1.444rem;
            line-height: 1.444rem;
            text-align: center;
            border: none;
            background: #ff9c00;
            text-transform: uppercase;
            color: #ffffff;
            border-radius: 10px;
            &:focus {
              outline: none;
            }
          }
        }
      }
    }
  }
}
</style>
